import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi. Scroll down.</h1>
        <h4>Despite the name, this is NOT a good personal website.</h4>
        <h4>I mean, it's okay. It's deployed, and it has a custom URL, and you're reading this, so something's working.</h4>
        <h4>But I think overall, if you were looking for a job, this website wouldn't really be doing you any favours.</h4>
        <h4>Wanna see some better personal websites?</h4>
        <a href="https://www.aarongoidel.com/" target="_blank" rel="noopener noreferrer">https://www.aarongoidel.com/</a>
        <a href="https://shawnhu.com/" target="_blank" rel="noopener noreferrer">https://shawnhu.com/</a>
        <h4>Yeah, these are way cooler. You want your website to look like these.</h4>
        <h4><i>Man, if only there was a way you could learn how to make a website from these two guys...</i> </h4>
        <h4>Oh wait, you can!!</h4>
        <h1>Join us at MCSS this Thursday, October 14th, at 8pm, as Aaron and Shawn take you through designing, coding, and deploying your very own personal website!</h1>
        <h1><a href="https://www.eventbrite.ca/e/personal-website-workshop-tickets-189869804697?" target="_blank" rel="noopener noreferrer">TICKETS ARE HERE, AND THEY'RE FREE</a> </h1>
        <h4>Don't miss this. Register now.</h4>
        <a href="https://linktr.ee/UTM_MCSS" target="_blank" rel="noopener noreferrer">(also check out our MCSS links)</a>

      </header>
    </div>
  );
}

export default App;
